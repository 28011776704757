import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DayInCalendar from './DayInCalendar'

const Calendar = (props) => {
    return (
        <div className={`xl:mt-3 mb-3 mt-4 sm:mt-none xl:mb-0 ${props.hiddenCallendar ? "hidden" : ""} md:block`}>
            <div className='pb-3 flex items-center'>
                <div className='text-2xl pr-3'>
                    <FontAwesomeIcon onClick={() => props.changeDate( 0 - parseInt(props.dateDayNumber))} icon="fa-solid fa-angles-left" className="text-brand cursor-pointer hover:text-white select-none"/>
                    <FontAwesomeIcon onClick={() => props.changeDate((props.daysInCurrentMonth.length)  - props.dateDayNumber +1)} icon="fa-solid fa-angles-right" className="text-brand cursor-pointer hover:text-white select-none"/>
                </div>
                
                <h3 className='text-2xl text-center'>
                    {props.dateMonth}, {props.dateYear}
                </h3>
            </div>
        
            <div className="grid grid-cols-7 gap-2">
                <span className='text-center pb-2'>
                    Pon
                </span>
                <span className='text-center pb-2'>
                    Wt
                </span>
                <span className='text-center pb-2'>
                    Śr
                </span>
                <span className='text-center pb-2'>
                    Czw
                </span>
                <span className='text-center pb-2'>
                    Pt
                </span>
                <span className='text-center pb-2'>
                    Sob
                </span>
                <span className='text-center pb-2'>
                    Nd
                </span>
            </div>
            <div className='grid grid-cols-7 gap-2'>
                {
                
                    props.daysInCurrentMonth.map((day) => (
                    
                        day === 1 ? ( 
                            
                                <DayInCalendar day={day} currentYear={props.currentYear} changeDate={props.changeDate} daysInCurrentMonth={props.daysInCurrentMonth} howManyTasks={props.howManyTasks} todayFormated={props.todayFormated} dateFormated={props.dateFormated}  marginDays={props.marginDays} dateMonth={props.dateMonth} dateYear={props.dateYear} hiddenCallendar={props.hiddenCallendar} dateDayNumber={props.dateDayNumber} currentDay={props.currentDay} currentMonth={props.currentMonth} moneyInCurrentMonth={props.moneyInCurrentMonth} tab={props.tab} isFirst={true} />    
                            
                           
                      ) : ( 
                           
                                  <DayInCalendar day={day} currentYear={props.currentYear} changeDate={props.changeDate} daysInCurrentMonth={props.daysInCurrentMonth} howManyTasks={props.howManyTasks} todayFormated={props.todayFormated} dateFormated={props.dateFormated}  marginDays={props.marginDays} dateMonth={props.dateMonth} dateYear={props.dateYear} hiddenCallendar={props.hiddenCallendar} dateDayNumber={props.dateDayNumber} currentDay={props.currentDay} currentMonth={props.currentMonth} moneyInCurrentMonth={props.moneyInCurrentMonth} tab={props.tab} isFirst={false} />
                            
                          
                                
                             )
                        
                        
                        ))
                        

                }
            </div>
        </div>
    )
}

export default Calendar