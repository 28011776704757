import logo from './logo.svg';
import './App.css';
import AllRoutes from "./routes/AllRoutes"

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'

library.add(fas)


function App() {
  return (
    <>
      <AllRoutes/>
    </>
  );
}

export default App;
