const Header = (props) => {


    return (
        <div className="sm:bg-zinc-900 rounded-full sm:mt-5 mx-auto w-full py-4 sm:px-6  mb-3 sm:mb-7 font-bold text-main text-2xl font-poppins text-center text-3xl ">
            <div className="container mx-auto flex flex-row justify-between items-center">
                <h2>
                    <img className="h-16" src={process.env.PUBLIC_URL + '/logo.png'} alt="Dayler Logo"/>
                </h2>
                
                <div className="font-normal ml-4 text-base flex gap-3">
                    <span className="cursor-pointer bg-brand py-2 px-6 text-md rounded-full hover:text-brand hover:bg-white" onClick={() => props.logout()}>Wyloguj</span>
                </div>
            </div>
            
        </div>
    )
}

export default Header