const DayInCalendar = (props) => {
    return (
    
            <div onClick={() => props.changeDate((`${props.day-props.dateDayNumber}`))} className={` 

            cursor-pointer  w-8 h-8 sm:h-14 sm:w-14 flex flex-col items-center justify-center rounded-lg sm:text-2xl

            ${props.tab === "tasks" ? "bg-green-500 hover:bg-white hover:text-green-500 " : "bg-gray-800 hover:bg-white hover:text-gray-800"}  
            
            ${(parseInt(props.todayFormated.substring(8,10)) === props.day && props.currentYear + "-" + props.currentMonth === props.dateFormated.substring(0,7)) ? "border-4 border-white" : ""}
            
            ${(props.day === props.dateDayNumber && props.howManyTasks.includes(props.day) && props.day && props.tab === "tasks") ? "font-bold" : ""}
            
            ${(props.day < props.currentDay && props.dateFormated.substring(0,7) === props.todayFormated.substring(0,7)) ? "text-gray-500 bg-zinc-800" : "" }
            
            ${(parseInt(props.dateFormated.substring(0,4)) < parseInt(props.currentYear)) || ( (parseInt(props.dateFormated.substring(5,7)) < parseInt(props.currentMonth)) && (parseInt(props.dateFormated.substring(0,4)) === parseInt(props.currentYear)) ) ? "text-gray-500 bg-zinc-800" : "" } 
            
            ${(props.howManyTasks.includes(props.day) && props.day !== props.dateDayNumber && props.tab === "tasks") ? "bg-green-700" : ""}

            ${((props.day < props.currentDay && props.dateFormated.substring(0,7) === props.todayFormated.substring(0,7)) && (props.howManyTasks.includes(props.day) && props.day !== props.dateDayNumber && props.tab === "tasks")) ? "!bg-zinc-700" : ""}

            ${parseInt(props.dateFormated.substring(8,10).replace("0", '')) === props.day ? "bg-white text-green-600" : ""}

            ${(   
                ((parseInt(props.dateFormated.substring(0,4)) < parseInt(props.currentYear)) 
                || 
                ( (parseInt(props.dateFormated.substring(5,7)) < parseInt(props.currentMonth)) && (parseInt(props.dateFormated.substring(0,4)) === parseInt(props.currentYear)) ) )


                && (props.howManyTasks.includes(props.day) && props.day !== props.dateDayNumber && props.tab === "tasks")) ? "!bg-zinc-700" : ""}



            


            

            ${( props.tab === "tasks" && props.day === props.dateDayNumber ) ? "!bg-white !text-green-500" : ""}
            ${( props.tab === "money" && props.day === props.dateDayNumber ) ? "!bg-white !text-gray-800" : ""}
            
            `} 
        style={
            props.isFirst === true ? {
                gridColumnStart: ( props.marginDays.length === 0 ? 1 : 8-props.marginDays.length)
            } : {

            }
            
        }>
            {props.day} 
            <small className={`text-xs h-4 text-green-900 font-bold ${props.tab === "money" ? "" : "hidden"} `}>
                {props.moneyInCurrentMonth!==undefined ? (
                props.moneyInCurrentMonth.map((mon) => (
                    mon.day === props.day ? (<span className={`${mon.amount>0 ? "text-green-500" : "text-red-500"} ${mon.amount===0 ? "text-gray-400" : ""}  font-bold`}>{mon.amount>0 ? "+" : ""}{mon.amount<0 ? "-" : ""}<span className='hidden sm:inline'>{mon.amount<0 ? -mon.amount : mon.amount}</span></span>) : ""
                ))
            ) : (<></>) } 
            </small>
        </div>
        
    
    )
}

export default DayInCalendar