import { Link } from "react-router-dom"
import { useEffect, useState } from "react"
import axios from "axios"
import { useNavigate } from "react-router-dom"
import { Helmet } from "react-helmet"

const Login = () => {

    axios.defaults.withCredentials = true

    const [ badLoginOrPassword, setBadLoginOrPassword ] = useState()
    const [ isLoading, setIsLoading ] = useState(false)

    function reg(e) {
        e.preventDefault()

      

        setIsLoading(true)

        const fetchUrl = process.env.REACT_APP_API_URL + "/register";
       

        axios.post(fetchUrl, {
            login: login,
            email: email,
            password: password,
            password2: password2,
        }).then((res) => {
            setIsLoading(false)

            if(res.data.message==="added") {
                setReloadLoginStatus(!reloadLoginStatus)
            } else {
                setBadLoginOrPassword(res.data.message)
            }
        })
    }

    /* USER AUTH */

   const [ loginStatus, setLoginStatus ] = useState()
   const [ reloadLoginStatus, setReloadLoginStatus ] = useState()
   

   function checkIfLogged() {
       axios.get(process.env.REACT_APP_API_URL + "/login").then((res) => {
          
           if(res.data.loggedIn === true) {
               setLoginStatus(res.data.user[0].login)
           }

           else {
               setLoginStatus(false)
           }
        
          
       })
   }

   useEffect(() => {
      checkIfLogged()
   }, [])

   useEffect(() => {
       checkIfLogged()
   }, [reloadLoginStatus])

   const navigate = useNavigate()

   useEffect(() => {
    if(loginStatus!==undefined) {
        if(loginStatus===false) {
            navigate('/register')
        }
 
        else {
            navigate('/')
        }
    }
       
   }, [loginStatus])

   /* USER AUTH */
   
   const [ login, setLogin ] = useState()
   const [ email, setEmail ] = useState()
   const [ password, setPassword ] = useState()
   const [ password2, setPassword2 ] = useState()

   


    
    return (
        loginStatus === undefined ? (
            <div className="w-full bg-white sm:!bg-transparent h-screen flex items-center justify-center">
                <img className="w-100 " src={process.env.PUBLIC_URL + '/loading.svg'} alt="loading"/>
            </div>
        ) : (
            <div className="w-full h-screen flex justify-center items-center bg-white sm:bg-transparent">
            <Helmet>
                <title>Rejestracja | DAYLER</title>
            </Helmet>
            <div className="">
                <div className="bg-white px-7 py-6 rounded-xl sm:w-[25rem]">
                    <h1 className="font-bold text-3xl text-center">Rejestracja</h1>{loginStatus}
                    <form className="mt-8 flex flex-col gap-3" onSubmit={reg}>
                        <input 
                            required
                            type="text" 
                            className="rounded-none border-b pb-1 text-xl" 
                            placeholder="* Nazwa użytkownika" 
                            onChange={(e) => setLogin(e.target.value)}
                            />
                        <input 
                        required
                        type="text" 
                        className="rounded-none border-b pb-1 text-xl" 
                        placeholder="* Email" 
                        onChange={(e) => setEmail(e.target.value)}
                        />
                        <input 
                            required
                            type="password" 
                            className="rounded-none border-b pb-1 text-xl" 
                            placeholder="* Hasło" 
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <input 
                            required
                            type="password" 
                            className="rounded-none border-b pb-1 text-xl" 
                            placeholder="* Powtórz hasło" 
                            onChange={(e) => setPassword2(e.target.value)}
                        />
                        <button className="bg-brand border-2 border-brand text-white px-5 py-2 rounded-full text-xl w-full hover:bg-white hover:text-brand">Zarejestruj się</button>
                        { isLoading ? ( 
                            <span className="text-black text-center h-10 flex items-center justify-center text-1xl"><img className="w-10" src={process.env.PUBLIC_URL + '/loading.svg'} alt="loading"/></span>
                         ) : ( 
                            badLoginOrPassword === undefined ? ( <></> ) : ( <span className="text-red-500 text-center h-10 flex items-center justify-center text-1xl">{badLoginOrPassword}</span> )
                         ) }
                    </form>
                    <div className="mt-3 text-center">
                        Masz już konto? <Link to="/login" className="underline hover:no-underline text-brand">Zaloguj się</Link>
                    </div>
                </div>
            </div>
            

        </div>
        )
        
    )
}

export default Login