const ProgressBar = (props) => {
    return (
        <div>      
            <div className='bg-white p-1 rounded-lg'>
                <div className='h-6 transition-all bg-green-600 rounded-lg text-white select-none flex items-center px-2' style={{
                    width: `${props.completedPercent}%`
                }}>
                    <span className='bg-green-600 rounded-r-lg pr-2'>{props.completedPercent}%</span>
                </div>
            </div>
        </div>
    )
}

export default ProgressBar