import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"


const NewTask = (props) => {
    return (
        <div className='announcement flex gap-4 flex-row bg-zinc-900 text-white'>
            <div className='flex flex-col gap-3 w-full'>
                <form onSubmit={props.handleSubmit}>
                    <div className='flex gap-1 flex-col add-forms'>
                        <textarea 
                            id="textareaTitle"
                            className='font-bold text-lg outline-none h-8 bg-zinc-900' 
                            placeholder='Nowe zadanie'
                            required
                            onChange={(e) => props.setNewTaskTitle(e.target.value)}
                            ></textarea>
                        <textarea
                            id="textareaDescription"
                            className='outline-none h-12 bg-zinc-900' 
                            placeholder='Nowe zadanie'
                            onChange={(e) => props.setNewTaskDescription(e.target.value)}
                            ></textarea>
                    </div>
                    <div className='controls flex items-center justify-between gap-4 '>
                        <div className='flex gap-2 justify-between 0 mt-2 w-full items-center'>
                            <button className='select-none hover:bg-gray-300 cursor-pointer bg-white text-black px-3 py-1 rounded-full'><FontAwesomeIcon icon="fa-solid fa-plus" className='select-none' /> Dodaj</button>

                            <div className="flex gap-2 items-center">
                                <FontAwesomeIcon icon="fa-solid fa-clock-rotate-left" className={`transition-all text-xl ${props.enabled ? "text-sky-500 rotate-[360deg]" : "text-red-500"}`} />
                                <div className="relative flex flex-col items-center justify-center overflow-hidden">
                                    <div className="flex">
                                        <label class="inline-flex relative items-center cursor-pointer">
                                            <input
                                                type="checkbox"
                                                className="sr-only peer"
                                                checked={props.enabled}
                                                readOnly
                                            />
                                            <div
                                                onClick={() => {
                                                    props.setEnabled(!props.enabled);
                                                }}
                                                className="w-11 h-6 bg-red-500 rounded-full peer  peer-focus:sky-300  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-sky-500"
                                            ></div>
                                        
                                        </label>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                        
                        
                    </div>
                    
                    
                </form>
            </div>
        </div>
    )
}

export default NewTask