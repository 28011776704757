import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Header from '../components/Header'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import Calendar from '../components/Calendar'
import Task from '../components/Task'
import NewTask from '../components/NewTask'
import ProgressBar from '../components/ProgressBar'
/*import MoneyTask from '../components/MoneyTask'
import NewMoneyTask from '../components/NewMoneyTask'
import MonthSummary from '../components/MonthSummary'*/




const Home = () => {

    axios.defaults.withCredentials = true

    const [ tasks, setTasks ] = useState()

    

    const [ allTasks, setAllTasks ] = useState()
    const [ completedTasks, setCompletedTask ] = useState(0)
    const [ completedPercent, setCompletedPercent ] = useState(0)


    

   /* USER AUTH */

   const [ loginStatus, setLoginStatus ] = useState()
   const [ reloadLoginStatus, setReloadLoginStatus ] = useState()
   

   function checkIfLogged() {
       axios.get(process.env.REACT_APP_API_URL + "/login").then((res) => {
       
           if(res.data.loggedIn === true) {
               setLoginStatus(res.data.user[0].login)
           }

           else {
               setLoginStatus(false)
           }
        
          
       })
   }

   useEffect(() => {
      checkIfLogged()
   }, [])

   useEffect(() => {
       checkIfLogged()
   }, [reloadLoginStatus])

   const navigate = useNavigate()

   useEffect(() => {
    if(loginStatus!==undefined) {
        if(loginStatus===false) {
            navigate('/login')
        }
 
        else {
            navigate('/')
        }
    }
       
   }, [loginStatus])


   function logout() {
        axios.get(process.env.REACT_APP_API_URL + "/logout").then((res) => {
    
            setReloadLoginStatus(!reloadLoginStatus)
        })
    }

   /* /USER AUTH */



   /* DATE */
    

    const today = new Date()
    const currentDay = String(today.getDate()).padStart(2, '0')
    const currentMonth = String(today.getMonth() + 1).padStart(2, '0')
    const currentYear = today.getFullYear()

    const days = new Array("Niedziela", "Poniedziałek", "Wtorek", "Środa",
  "Czwartek", "Piątek", "Sobota");
    const months = new Array("Styczeń", "Luty", "Marzec", "Kwiecień", "Maj",
    "Czerwiec", "Lipiec", "Sierpień", "Wrzesień", "Październik", "Listopad",
    "Grudzień");

    const td = today.getDay()
    const tm = today.getMonth()
    const ty = today.getFullYear()
    const tdn = String(today.getDate())



    const todayFormated = currentYear+"-"+currentMonth+"-"+currentDay
    

   
 


    const [ date, setDate ] = useState(today)
    const [ dateFormated, setDateFormated ] = useState(todayFormated)
    const [ dateDay, setDateDay ] = useState(days[td])
    const [ dateDayNumber, setDateDayNumber ] = useState(tdn)
    const [ dateMonth, setDateMonth ] = useState(months[tm])
    const [ dateYear, setDateYear ] = useState(ty)

   

    function changeDate(mode) {
        document.getElementById('textareaTitle').value = '';
        document.getElementById('textareaDescription').value = '';
        setNewTaskTitle("")
        setNewTaskDescription("")
        setEnabled(false)

        /*document.getElementById('textarea2Amount').value = '';
        document.getElementById('textarea2Description').value = '';
        setNewMoneyAmount("")
        setNewMoneyDescription("")
        setEnabled(false)*/

  

        switch (mode) {
            case 'next':
            
                date.setDate(date.getDate() + 1)

                const currentDay2 = String(date.getDate()).padStart(2, '0')
                const currentMonth2 = String(date.getMonth() + 1).padStart(2, '0')
                const currentYear2 = date.getFullYear()


                const td2 = date.getDay()
                setDateDay(days[td2])

                const tm2 = date.getMonth()
                setDateMonth(months[tm2])

                const ty2 = date.getFullYear()
                setDateYear(ty2)

                const tdn2 = String(date.getDate())
                setDateDayNumber(tdn2)


                setDateFormated(currentYear2+"-"+currentMonth2+"-"+currentDay2)

               
                break;
            case 'previous':
             
                date.setDate(date.getDate() - 1)

                const currentDay3 = String(date.getDate()).padStart(2, '0')
                const currentMonth3 = String(date.getMonth() + 1).padStart(2, '0')
                const currentYear3 = date.getFullYear()

                const td3 = date.getDay()
                setDateDay(days[td3])

                const tm3 = date.getMonth()
                setDateMonth(months[tm3])

                const ty3 = date.getFullYear()
                setDateYear(ty3)

                const tdn3 = String(date.getDate())
                setDateDayNumber(tdn3)

                setDateFormated(currentYear3+"-"+currentMonth3+"-"+currentDay3)
                break;
            default:
                
                mode = parseInt(mode)

          
         
                date.setDate(date.getDate() + mode)
                
              
    

                const currentDay4 = String(date.getDate()).padStart(2, '0')
                const currentMonth4 = String(date.getMonth() + 1).padStart(2, '0')
                const currentYear4 = date.getFullYear()

                const td4 = date.getDay()
                setDateDay(days[td4])

                const tm4 = date.getMonth()
                setDateMonth(months[tm4])

                const ty4 = date.getFullYear()
                setDateYear(ty4)

                const tdn4 = String(date.getDate())
                setDateDayNumber(tdn4)

                setDateFormated(currentYear4+"-"+currentMonth4+"-"+currentDay4)
                break;
        }
    }

    function getDaysInMonth(year, month) {
        return new Date(year, month, 0).getDate();
      }

    const [ daysInCurrentMonth, setDaysInCurrentMonth ] = useState([])

    const [ marginDays, setMarginDays ] = useState([])


   
    
    

    useEffect(() => {
        
        setDaysInCurrentMonth([])
      

        setMarginDays([])
        var isMon = false
        
        for (let i = 1; i <= getDaysInMonth(dateYear, date.getMonth()+1); i++) {

            var dateFormated2 = dateFormated.substring(0,8)

            if(i<10) {
                var i2 = "0" + i
            } else {
                i2 = i
            }

            dateFormated2 = dateFormated2 + i2

         
            var thisDate = new Date(dateFormated2)
           
       

            if(thisDate.getDay()!==1 && isMon===false) {
                setMarginDays(marginDays => [...marginDays, ("|"+i+"|")])
             
            } else {
                isMon = true
            }
            
            
            setDaysInCurrentMonth(daysInCurrentMonth => [...daysInCurrentMonth, i]);
            
        }

        
       
        
    }, [date, dateFormated])


    const [ howManyTasks, setHowManyTasks ] = useState([])


    function setCheckDays(data) {
        data.map((record) => {
           
            var dateToAdd = record.date
           

            var beg = dateToAdd.substring(8, 10)
            beg = parseInt(beg)

           


            dateToAdd = dateToAdd.substring(0, 8)
            dateToAdd = dateToAdd+beg
            
            
          
            dateToAdd = dateToAdd.substring(0,10)
   
            setHowManyTasks(howManyTasks => [...howManyTasks, beg]);
           
            
           })
    }

    async function doWork(data) {
        

        await setCheckDays(data)


    }


    /* /DATE */

    
    /* TASKS */

    function mapTasks(data) {
        return new Promise((resolve, reject) => {
            var  x = 0
            data.map((task) => {
                if(task.completed) {
                    x = x + 1
                }
            })
          
           
            resolve(x)
        })
    }

    async function getPercent(data) {
        const render1 = await mapTasks(data)
        const render2 = await getAllTasks(data)

        var percent = (render1/render2) * 100

        percent = Math.round(percent)
        
        if(render2===0) {
            setCompletedPercent(100)
        } else {
            setCompletedPercent(percent)
        }
        
    }

    async function renderCompletedTasks(data) {
        const render = await mapTasks(data)
      

        setCompletedTask(render)
    }

    function mapTasks(data) {
        return new Promise((resolve, reject) => {
            var  x = 0
            data.map((task) => {
                if(task.completed) {
                    x = x + 1
                }
            })
          
           
            resolve(x)
        })
    }
    
    function getAllTasks(data) {
        return new Promise((resolve, reject) => {
            const render = data.length
            resolve(render)
        })
    }

    async function renderAllTasks(data) {
        const render = await getAllTasks(data)

        setAllTasks(render)
    }

    const [ tasksModified, setTasksModified ] = useState(0)

    
    
   

    useEffect(() => {
         
        if((loginStatus !== undefined && loginStatus!==false )) {
        
            setTasks(undefined)

       

            axios.post(process.env.REACT_APP_API_URL + "/tasks", {
                dateFormated: dateFormated,
            }).then((res) => {
       
                renderAllTasks(res.data)
                setTasks(res.data)
                
    
                renderCompletedTasks(res.data)
                getPercent(res.data)
               
                document.getElementById('textareaAdd').value = '';
                
                
            })

        }
       

        
    }, [tasksModified, date, dateFormated, loginStatus])




    const [newTaskTitle, setNewTaskTitle] = useState("")
    const [newTaskDescription, setNewTaskDescription] = useState("")

    

    const handleSubmit = (e) => {
        e.preventDefault()

        var newTask = { newTaskTitle, newTaskDescription, dateFormated }

       
        


        axios.post(process.env.REACT_APP_API_URL + '/addTask', {
            newTaskTitle: newTaskTitle,
            newTaskDescription: newTaskDescription,
            dateFormated: dateFormated,
            everyday: enabled,
        }).then((res) => {
            setTasksModified(tasksModified+1)
            document.getElementById('textareaTitle').value = '';
            document.getElementById('textareaDescription').value = '';
            setNewTaskTitle("")
            setNewTaskDescription("")
            setEnabled(false)
        })
    }

   

  


   



    function checkIfTasksExists(date) {
        var data = { date }
        setHowManyTasks([])


  

        axios.post(process.env.REACT_APP_API_URL + '/tasksExists', {
            date: date
        }).then((res) => {
       
            doWork(res.data)   
        })
    }


    useEffect(() => {
        if((loginStatus !== undefined && loginStatus!==false )) { 
            checkIfTasksExists(dateFormated.substring(0,7))
        }
       
    }, [dateMonth, tasksModified, loginStatus])



    function checkHowManyRegularTasksToDo(data) {
        return new Promise((resolve, reject) => {
            var howManyRegularToDo = 0
            var howManyEveryRegular = 0
            data.map((singleRegularTask) => {
                howManyEveryRegular++
                if(singleRegularTask.everyday!=todayFormated) {
                    howManyRegularToDo++
                }
            })

            var response = [
                howManyRegularToDo, howManyEveryRegular
            ]

            resolve(response)
        })
       

       
    }

    async function renderRegularTasks(data) {
        const render = await checkHowManyRegularTasksToDo(data)

        setHowManyRegularTasksToDo(render)
    }

    const [ howManyRegularTasksToDo, setHowManyRegularTasksToDo ] = useState()

    const [ regularTasks, setRegularTasks ] = useState()

    useEffect(() => {
         
        if((loginStatus !== undefined && loginStatus!==false )) {
        
            setRegularTasks(undefined)

       

            axios.post(process.env.REACT_APP_API_URL + "/regularTasks", {
                
            }).then((res) => {
               
                setRegularTasks(res.data)

                renderRegularTasks(res.data)

                
            
               
                document.getElementById('textareaAdd').value = '';
                
                
            })

        }
       

        
    }, [tasksModified, date, dateFormated, loginStatus])




    /* /TASKS */
   

    const [ hiddenCallendar, setHiddenCallendar ] = useState(true)

    const [ tab, setTab ] = useState('tasks')

    function changeTab(tabName) {
        setTab(tabName)
    }


    const [enabled, setEnabled] = useState(false);


    /* MONEY 

    const [enabled, setEnabled] = useState(false);

    const [ money, setMoney ] = useState()
    const [ allMoney, setAllMoney ] = useState()
    const [ moneyModified, setMoneyModified ] = useState(0)

    const [ newMoneyAmount, setNewMoneyAmount ] = useState("")
    const [ newMoneyDescription, setNewMoneyDescription ] = useState("")
    const [ newMoneyType, setNewMoneyType ] = useState("")

    useEffect(() => {
        if(enabled) {
            setNewMoneyType("+")
        } else {
            setNewMoneyType("-")
        }
    }, [enabled])

    useEffect(() => {

        if((loginStatus != undefined && loginStatus!=false )) {
            setMoney(undefined)



            axios.post(process.env.REACT_APP_API_URL + '/money', {
                dateFormated: dateFormated,
            }).then((res) => {
                setMoney(res.data)
                
                setAllMoney(res.data.length)
            })
        }
         

        
    }, [moneyModified, date, dateFormated, loginStatus])

    const handleSubmit2 = (e) => {
        e.preventDefault()


        axios.post(process.env.REACT_APP_API_URL + '/addMoney', {
            newMoneyAmount: newMoneyAmount,
            newMoneyDescription: newMoneyDescription,
            newMoneyType: newMoneyType,
            dateFormated: dateFormated,
        }).then((res) => {
            setMoneyModified(moneyModified+1)
            document.getElementById('textarea2Amount').value = '';
            document.getElementById('textarea2Description').value = '';
            setNewMoneyAmount("")
            setNewMoneyDescription("")
            setEnabled(false)
        })
    }



    const [ earnedToday, setEarnedToday ] = useState(0)
    const [ spentToday, setSpentToday ] = useState(0)
    const [ percent2, setPercent2 ] = useState(0)
    const [ todayBalance, setTodayBalance ] = useState(0)

    

    function countEarned(data) {
        return new Promise((resolve, reject) => {
            var earned = 0
            data.map((mon) => {
                if(mon.type=="+") {
                    earned = earned + mon.amount
                }
            })
            resolve(earned)
        })
    }

    function countSpended(data) {
        return new Promise((resolve, reject) => {
            var spended = 0
            data.map((mon) => {
                if(mon.type=="-") {
                    spended = spended + mon.amount
                }
            })
            resolve(spended)
        })
    }

    function getPercent2(earned, spended) {
        return new Promise((resolve, reject) => {

            var prcnt = 0

            if(spended>earned) {
                prcnt = 100
            }

            else if (spended==0 && earned==0) {
                prcnt = 0
            }

            else {
                prcnt = (spended/earned) * 100
            }

            
            

            prcnt = Math.round(prcnt)
            resolve(prcnt)
        })
    }

    function getBalance(earned, spended) {
        return new Promise((resolve, reject) => {
            var balance = earned - spended

            resolve(balance)
        })
    }

    async function doWork2(data) {
        
        var earned = await countEarned(data)
        var spended = await countSpended(data)
        var prc2 = await getPercent2(earned, spended)
        var blnc = await getBalance(earned, spended)
        setEarnedToday(earned)
        setSpentToday(spended)
        setPercent2(prc2)
        setTodayBalance(blnc)


    }

    useEffect(() => {
        if(money!=undefined) {
           doWork2(money)
        }
      

    }, [money])


    const [ moneyInCurrentMonth, setMoneyInCurrentMonth ] = useState()

    function sumUpMonth(data) {
        return new Promise((resolve, reject) => {
            var sum = 0
            data.map((day) => {
                sum = sum + day.amount
            })

            resolve(sum)
        })
    }

   

    const [monthSum, setMonthSum] = useState()
    const [monthEarnedSum, setMonthEarnedSum] = useState()
    const [monthSpentSum, setMonthSpentSum] = useState()

    async function doWork3(data) {
        const sum = await sumUpMonth(data)
        setMonthSum(sum)
       
        
    }

    useEffect(() => {
        if((loginStatus != undefined && loginStatus!=false )) {
            axios.post(process.env.REACT_APP_API_URL + '/moneyInMonth', {
                date: dateFormated
            }).then((res) => {
                
                doWork3(res.data)
                setMoneyInCurrentMonth(res.data)
                
            })
        }
       
    }, [dateMonth, money, loginStatus])

    

    useEffect(() => {
        if((loginStatus != undefined && loginStatus!=false )) {
            axios.post(process.env.REACT_APP_API_URL + '/earnedInMonth', {
                date: dateFormated
            }).then((res) => {
                
                console.log(res.data)
                setMonthEarnedSum(res.data)
                
            })
        }
       
    }, [dateMonth, money, loginStatus])

    useEffect(() => {
        if((loginStatus != undefined && loginStatus!=false )) {
            axios.post(process.env.REACT_APP_API_URL + '/spentInMonth', {
                date: dateFormated
            }).then((res) => {
                
                setMonthSpentSum(res.data)
                
            })
        }
       
    }, [dateMonth, money, loginStatus])
    

     MONEY */

     function deactivateRegularTask(taskId) {
        
        const taskToDeactivate = { taskId, todayFormated }

        const fetchUrl = process.env.REACT_APP_API_URL + "/deactivateRegularTask";
        
     
        fetch(fetchUrl, {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(taskToDeactivate)
        }).then((res) => {
      
            setTasksModified(tasksModified+1)
        })

    }

    function activateRegularTask(taskId) {
        
        const taskToActivate = { taskId }

        const fetchUrl = process.env.REACT_APP_API_URL + "/activateRegularTask";

        fetch(fetchUrl, {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(taskToActivate)
        }).then((res) => {
           
            setTasksModified(tasksModified+1)
        })

    }

    function removeRegularTask(taskId) {
        
        const taskToRemove = { taskId }

        const fetchUrl = process.env.REACT_APP_API_URL + "/removeRegularTask";

        fetch(fetchUrl, {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(taskToRemove)
        }).then((res) => {
           
            setTasksModified(tasksModified+1)
        })

    }

    return (
        (loginStatus === undefined || loginStatus===false ) ? (

            <div className="w-full h-screen flex items-center justify-center">
                <img className="w-100 " src={process.env.PUBLIC_URL + '/loading.svg'} alt="loading"/>
            </div>

        ) : (

            <div className="container mx-auto h-screen flex flex-col px-5 select-none">
            <Header logout={logout}/>
            <Helmet>
                <title>DAYLER</title>
            </Helmet>

           <div className='flex justify-between'>
                <div>
                    
                </div>
                 {/*<div className='flex   gap-4 sm:gap-0   pt-2  rounded-tr-xl rounded-tl-xl'>
                    <div onClick={(e) => changeTab("tasks")} className={`rounded-t-xl sm:py-3 sm:px-5 flex justify-center gap-2 text-4xl md:text-3xl  cursor-pointer ${tab==="tasks" ? "text-brand sm:bg-zinc-800" : "text-white  hover:text-brand"}`}>
                        <FontAwesomeIcon icon="fa-solid fa-list-check" /> <small className='hidden sm:block text-xl'>Zadania</small>
                    </div>
                    <div onClick={(e) => changeTab("money")} className={`rounded-t-xl sm:py-3 sm:px-5 flex justify-center gap-2 text-4xl md:text-3xl  cursor-pointer ${tab==="money" ? "text-brand sm:bg-zinc-800" : "text-white hover:text-brand"}`}>
                        <FontAwesomeIcon icon="fa-solid fa-file-invoice-dollar" />  <small className='hidden sm:block text-xl'>Finanse</small>
                    </div>
                </div>*/}
                <div className={`md:hidden text-4xl ${hiddenCallendar ? "text-white" : "text-brand"}`}>
                    <FontAwesomeIcon icon="fa-solid fa-calendar-days" onClick={() => setHiddenCallendar(!hiddenCallendar) } />
                </div>
            </div>
            <section className={` flex-1 xl:overflow-hidden flex flex-col xl:flex-row      sm:px-4 rounded-tr-xl ${tab !== "tasks" ? "rounded-t-xl" : ""}`}>
               
                <div className='w-full xl:w-1/2 flex flex-col order-2 xl:order-1'>
                    
                    <div className="text-34xl mb-2 pt-2 pb-1  flex-col md:flex-row flex md:justify-start gap-4 w-full items-center">
                       
                        

                        <div className='flex gap-2 py-2  justify-center w-full md:py-0 md:justify-start md:w-auto text-5xl md:text-2xl'>
                            <FontAwesomeIcon onClick={() => changeDate("previous")} icon="fa-solid fa-angles-left" className="text-brand cursor-pointer hover:text-white select-none"/>
                            <FontAwesomeIcon onClick={() => changeDate("next")} icon="fa-solid fa-angles-right" className="text-brand cursor-pointer hover:text-white select-none"/>
                        </div>
                        
                        <small className={`md:block text-xl select-none text-white `}> {todayFormated === dateFormated ? (<><b className="text-brand">Dzisiaj</b></>) : (<></>)} {dateDay}, {dateDayNumber} {dateMonth} {dateYear}</small>
                        
                    </div>
                    <div className='announcements p-2 flex-1 xl:overflow-y-scroll flex flex-col xl:pr-2 pb-4 px-4 pt-4 rounded-xl'>
                        <div className={`flex-col gap-3 ${tab==="tasks" ? "flex" : "hidden"}`}>
                            <ProgressBar completedPercent={completedPercent} />
                    
                            <NewTask setNewTaskTitle={setNewTaskTitle} setNewTaskDescription={setNewTaskDescription} handleSubmit={handleSubmit} enabled={enabled} setEnabled={setEnabled} />

                            { ((newTaskTitle === "") && (newTaskDescription === ""))  ? (
                                <></>
                            ) : (
                                <Task title={newTaskTitle} description={newTaskDescription} completed={0} demo={true} enabled={enabled} />
                            )}
                            { typeof tasks === 'undefined' || regularTasks === 'undefined' ? (
                                <>
                                    <div className="w-full py-4 flex items-center justify-center">
                                        <img className="w-16 " src={process.env.PUBLIC_URL + '/loading.svg'} alt="loading"/>
                                    </div>
                                </>
                            ) : (
                                tasks.map((task) => (
                                    <Task id={task.id} title={task.title} description={task.description} completed={task.completed} demo={false} tasksModified={tasksModified} setTasksModified={setTasksModified} date={date} />
                                    ))
                                )}
                                { (allTasks === 0 && tasks!==undefined) ? (<>
                                    {dateFormated === todayFormated ? <>
                                        { howManyRegularTasksToDo !== undefined && howManyRegularTasksToDo[1] === 0 ? <>
                                            <div className='text-zinc-600 font-medium text-xl pl-1 mb-1'>
                                                Brak zadań
                                            </div>
                                        </> : <>
                                        
                                        </>}
                                    </> : <>
                                        <div className='text-zinc-600 font-medium text-xl pl-1 mb-1'>
                                            Brak zadań
                                        </div>
                                    </>}
                                    
                                </>) : (<>
                                    </>
                                )}


                             
                        </div>
                       
                        {
                            regularTasks !== undefined ? <>
                                {dateFormated === todayFormated ? <>
                                    
                                    {regularTasks.map((regularTask) => (
                               
                               <div className={`mt-2 announcement flex gap-4 flex-row border-4 border-sky-500 ${regularTask.everyday === todayFormated ? "opacity-40" : ""}`}>
                               <div className='flex flex-col gap-3 w-full'>
                                   <div className='flex gap-1 flex-col'>
                                       <h3 className='font-bold text-lg min-h-[1.8rem]'>{regularTask.title}</h3>
                                       <p>{regularTask.description}</p>
                                   </div>
                                   <div className='controls flex justify-between'>
                                       <div className="flex gap-2">
                                        {regularTask.everyday !== todayFormated ? (
                                                <span onClick={() => deactivateRegularTask(regularTask.id)} className='hover:bg-sky-500 select-none hover:text-white cursor-pointer rounded-full text-xl border-2 border-sky-500 text-sky-500  w-8 h-8 flex items-center justify-center'>
                                                <FontAwesomeIcon icon="fa-solid fa-check" /></span>
                                            ) : (
                                                <span onClick={() => activateRegularTask(regularTask.id)} className='hover:bg-white select-none bg-sky-500 hover:text-sky-500 cursor-pointer rounded-full text-xl border-2 border-sky-500 text-white w-8 h-8 flex items-center justify-center'>
                                                <FontAwesomeIcon icon="fa-solid fa-check" /></span>
                                            )}
                                            <span onClick={() => removeRegularTask(regularTask.id)} className='hover:bg-red-600 select-none cursor-pointer bg-red-500 text-white px-3 py-1 rounded-full'><FontAwesomeIcon icon="fa-solid fa-trash" /> usuń</span>
                                       </div>
                                   
                                      
                                       <div className='flex gap-2'>
                                          
                                       </div>
                                   </div>
                               </div>
                           </div>


                                ))}
                                
                                </> : <>
                                
                                </>}
                                 
                            </> : <>
                            </>
                        }

                        <div className={`flex-col gap-3 ${tab==="money" ? "flex" : "hidden"}`}>
                            {/*<div>      
                                <div className='bg-white p-1 rounded-lg'>
                                    <div className={`h-6 relative flex justify-between transition-all ${spentToday===0 && earnedToday===0 ? "bg-gray-300" : "bg-green-600"}  rounded-lg text-white select-none flex items-center`}>
                                        <div className={`${spentToday===0 ? "px-0" : "px-2"} absolute bg-red-500  h-full transition-all rounded-lg`} style={{
                                            width: `${percent2}%`
                                        }}>
                                            
                                        </div>
                                        <div className={`${spentToday===0 && earnedToday===0 ? "hidden" : ""} absolute flex justify-between w-full px-2`}>
                                            <span className='rounded-r-lg pr-2'></span>
                                            <span className='rounded-r-lg pl-2'><b className='font-semibold'>{todayBalance>0 ? "+" : ""}{todayBalance}zł</b></span>
                                        </div>
                                      
                                    </div>
                                </div>
                            </div>
                            <NewMoneyTask handleSubmit2={handleSubmit2} enabled={enabled} setEnabled={setEnabled} setNewMoneyAmount={setNewMoneyAmount} setNewMoneyDescription={setNewMoneyDescription}/>



                            { ((newMoneyAmount === "") && (newMoneyDescription === ""))  ? (
                                <></>
                            ) : (
                                <MoneyTask  amount={newMoneyAmount} type={newMoneyType} description={newMoneyDescription} demo={true} />
                            )}



                            { typeof money === 'undefined' ? (
                                <></>
                            ) : (
                                money.map((mon) => (
                                    <MoneyTask  id={mon.id}  amount={mon.amount} type={mon.type} description={mon.description} moneyModified={moneyModified} setMoneyModified={setMoneyModified} />
                                    ))
                                )}
                                { allMoney !== 0 ? (<></>) : (<>
                                    <div className='text-zinc-600 font-medium text-xl pl-1'>
                                        Brak zarobków/wydatków
                                    </div></>
                                )}
                                */}

                        </div>
                        
                    </div>
                    
                </div>
                
                

                <div className='w-full flex xl:w-1/2 text-main order-1 items-center flex-col overflow-y-scroll'>
                    <Calendar currentYear={currentYear} changeDate={changeDate} daysInCurrentMonth={daysInCurrentMonth} howManyTasks={howManyTasks} todayFormated={todayFormated} dateFormated={dateFormated}  marginDays={marginDays} dateMonth={dateMonth} dateYear={dateYear} hiddenCallendar={hiddenCallendar} dateDayNumber={dateDayNumber} currentDay={currentDay} currentMonth={currentMonth} /*moneyInCurrentMonth={moneyInCurrentMonth}*/ tab={tab} />

                    {/*<MonthSummary hiddenCallendar={hiddenCallendar} monthSum={monthSum} monthEarnedSum={monthEarnedSum} monthSpentSum={monthSpentSum}  tab={tab} />*/}
                </div>

            </section>
         
        </div>

        )
        
    )
}

export default Home