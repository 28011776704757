import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Task = (props) => {

    axios.defaults.withCredentials = true
    
    function deactivateTask(taskId) {
        
        const taskToDeactivate = { taskId }

        const fetchUrl = process.env.REACT_APP_API_URL + "/deactivateTask";
        
     
        fetch(fetchUrl, {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(taskToDeactivate)
        }).then((res) => {
      
            props.setTasksModified(props.tasksModified+1)
        })

    }

    function activateTask(taskId) {
        
        const taskToActivate = { taskId }

        const fetchUrl = process.env.REACT_APP_API_URL + "/activateTask";

        fetch(fetchUrl, {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(taskToActivate)
        }).then((res) => {
           
            props.setTasksModified(props.tasksModified+1)
        })

    }

    function removeTask(taskId) {
        
        const taskToRemove = { taskId }

        const fetchUrl = process.env.REACT_APP_API_URL + "/removeTask";

        fetch(fetchUrl, {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(taskToRemove)
        }).then((res) => {
           
            props.setTasksModified(props.tasksModified+1)
        })

    }

    function moveTask(taskId, mode) {
     

        switch (mode) {
            case 'next':
                console.log("1: " + props.date)
                var newDate = new Date(props.date)
                newDate.setDate(newDate.getDate() + 1)
                
                console.log("2: " + props.date)

                const newCurrentDay = String(newDate.getDate()).padStart(2, '0')
                const newCurrentMonth = String(newDate.getMonth() + 1).padStart(2, '0')
                const newCurrentYear = newDate.getFullYear()
              

                const newDateFormated = newCurrentYear+"-"+newCurrentMonth+"-"+newCurrentDay

                console.log("move next" + newDateFormated)

                
                axios.post(process.env.REACT_APP_API_URL + '/moveTask', {
                    id: taskId,
                    mode: mode,
                    newDate: newDateFormated,
                }).then((res) => {
                    console.log("3: " + props.date)
                    props.setTasksModified(props.tasksModified+1)
                    
                })
               
                break;
            case 'previous':
                var newDate2 = new Date(props.date)
                newDate2.setDate(newDate2.getDate() - 1)
                
                const newCurrentDay2 = String(newDate2.getDate()).padStart(2, '0')
                const newCurrentMonth2 = String(newDate2.getMonth() + 1).padStart(2, '0')
                const newCurrentYear2 = newDate2.getFullYear()
              

                const newDateFormated2 = newCurrentYear2+"-"+newCurrentMonth2+"-"+newCurrentDay2

                console.log("previous" + newDateFormated2)

                
                axios.post(process.env.REACT_APP_API_URL + '/moveTask', {
                    id: taskId,
                    mode: mode,
                    newDate: newDateFormated2,
                }).then((res) => {
           
                    props.setTasksModified(props.tasksModified+1)
                    
                })
                
                break;
        }
    }

    return (
        <div className={`announcement flex gap-4 flex-row ${props.completed ? "opacity-40" : "" } ${props.demo ? "border-8 border-green-300" : "" } ${props.enabled ? "border-8 border-blue-300" : "" } `}>
            <div className='flex flex-col gap-3 w-full'>
                <div className='flex gap-1 flex-col'>
                    <h3 className='font-bold text-lg min-h-[1.8rem]'>{props.title}</h3>
                    <p>{props.description}</p>
                </div>
                <div className='controls flex justify-between'>
                    <div className="flex gap-2">
                        {props.completed === 0 ? (
                            <span onClick={() => ( props.demo ? ( '' ) : ( deactivateTask(props.id) ) )} className='hover:bg-green-500 select-none hover:text-white cursor-pointer rounded-full text-xl border-2 border-green-500 text-green-500  w-8 h-8 flex items-center justify-center'>
                            <FontAwesomeIcon icon="fa-solid fa-check" /></span>
                        ) : (
                            <span onClick={() => ( props.demo ? ( '' ) : ( activateTask(props.id) ) )} className='hover:bg-white select-none bg-green-500 hover:text-green-500 cursor-pointer rounded-full text-xl border-2 border-green-500 text-white w-8 h-8 flex items-center justify-center'>
                            <FontAwesomeIcon icon="fa-solid fa-check" /></span>
                        )}
                        <span onClick={() => ( props.demo ? ( '' ) : ( removeTask(props.id) ) )} className='hover:bg-red-600 select-none cursor-pointer bg-red-500 text-white px-3 py-1 rounded-full'><FontAwesomeIcon icon="fa-solid fa-trash" /> usuń</span>
                    </div>
                
                   
                    <div className='flex gap-2'>
                        <span onClick={() => ( props.demo ? ( '' ) : ( moveTask(props.id, "previous") ) )} className='hover:bg-white select-none bg-gray-900 hover:text-gray-900 cursor-pointer rounded-full text-xl border-2 border-gray-900 text-white w-8 h-8 flex items-center justify-center'>
                        <FontAwesomeIcon icon="fa-solid fa-angles-left" /></span>
                        <span onClick={() => ( props.demo ? ( '' ) : ( moveTask(props.id, "next") ) )} className='hover:bg-white select-none bg-gray-900 hover:text-gray-900 cursor-pointer rounded-full text-xl border-2 border-gray-900 text-white w-8 h-8 flex items-center justify-center'>
                        <FontAwesomeIcon icon="fa-solid fa-angles-right" /></span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Task