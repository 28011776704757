import { Routes, Route } from "react-router-dom"
import Home from "./Home"
import Login from "./Login"
import Register from "./Register"
import Test from "./Test"

const AllRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<Home/>}></Route>
            <Route path="/login" element={<Login/>}></Route>
            <Route path="/register" element={<Register/>}></Route>
            <Route path="/test" element={<Test/>}></Route>
        </Routes>
    )
}

export default AllRoutes