import { Link } from "react-router-dom"
import { useEffect, useState } from "react"
import axios from "axios"
import { useNavigate } from "react-router-dom"
import { Helmet } from "react-helmet"

const Login = () => {

    axios.defaults.withCredentials = true

    const [ badLoginOrPassword, setBadLoginOrPassword ] = useState()
    const [ isLoading, setIsLoading ] = useState(false)

    const [ login, setLogin ] = useState()
    const [ password, setPassword ] = useState()

    function log(e) {
        e.preventDefault()

        setIsLoading(true)

        const fetchUrl = process.env.REACT_APP_API_URL + "/login";
       

        axios.post(fetchUrl, {
            login: login,
            password: password,
        }).then((res) => {
           setIsLoading(false)
            if(res.data.message==="error") {
                setBadLoginOrPassword("błędny login lub hasło")
            }
            setReloadLoginStatus(!reloadLoginStatus)
            
        })
    }

    /* USER AUTH */

   const [ loginStatus, setLoginStatus ] = useState()
   const [ reloadLoginStatus, setReloadLoginStatus ] = useState()
   

   function checkIfLogged() {
       axios.get(process.env.REACT_APP_API_URL + "/login").then((res) => {
          
           if(res.data.loggedIn === true) {
               setLoginStatus(res.data.user[0].login)
           }

           else {
               setLoginStatus(false)
           }
        
          
       })
   }
   

   useEffect(() => {
      checkIfLogged()
   }, [])

   useEffect(() => {
       checkIfLogged()
   }, [reloadLoginStatus])

   const navigate = useNavigate()

   useEffect(() => {
    if(loginStatus!==undefined) {
        if(loginStatus===false) {
            navigate('/login')
        }
 
        else {
            navigate('/')
        }
    }
       
   }, [loginStatus])

   /* USER AUTH */
   




    
    return (
        loginStatus === undefined ? (
            <div className="w-full bg-white sm:!bg-transparent h-screen flex items-center justify-center">
                <img className="w-100 " src={process.env.PUBLIC_URL + '/loading.svg'} alt="loading"/>
            </div>
        ) : (
            <div className="w-full h-screen flex justify-center items-center bg-white sm:bg-transparent">
            <Helmet>
                <title>Logowanie | DAYLER</title>
            </Helmet>
            <div className="">
                <div className="bg-white px-7 py-6 rounded-xl sm:w-[25rem]">
                    <h1 className="font-bold text-3xl text-center">Logowanie</h1>{loginStatus}
                    <form className="mt-8 flex flex-col gap-3" onSubmit={log}>
                        <input 
                            required
                            type="text" 
                            className="rounded-none border-b pb-1 text-xl" 
                            placeholder="Login / Email" 
                            onChange={(e) => setLogin(e.target.value)}
                            />
                        <input 
                            required
                            type="password" 
                            className="rounded-none border-b pb-1 text-xl" 
                            placeholder="Hasło" 
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <button className="bg-brand border-2 border-brand text-white px-5 py-2 rounded-full text-xl w-full hover:bg-white hover:text-brand">Zaloguj</button>
                        { isLoading ? ( 
                            <span className="text-black text-center h-10 flex items-center justify-center text-1xl"><img className="w-10" src={process.env.PUBLIC_URL + '/loading.svg'} alt="loading"/></span>
                         ) : ( 
                            badLoginOrPassword === undefined ? ( <></> ) : ( <span className="text-red-500 text-center h-10 flex items-center justify-center text-1xl">{badLoginOrPassword}</span> )
                         ) }
                       
                    </form>
                    <div className="mt-3 text-center">
                        Nie masz konta? <Link to="/register" className="underline hover:no-underline text-brand">Zarejestruj się</Link>
                    </div>
                </div>
            </div>
            

        </div>
        )
        
    )
}

export default Login